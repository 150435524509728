<template>
  <FullscreenModal
    :isActive="showConfirmDelete"
    @modalCompleted="handleModalCompleted"
  >
    <template v-slot:body>{{
      i18n.t('components.productionOrderCard.deleteMainText')
    }}</template>
    <template v-slot:confirmBtn>{{
      i18n.t('components.productionOrderCard.deleteConfirmAction')
    }}</template>
    <template v-slot:cancelBtn>{{
      i18n.t('components.productionOrderCard.deleteCancelAction')
    }}</template>
  </FullscreenModal>
  <div v-show="!isDeleted" class="row justify-content-center">
    <div class="col col-md-6">
      <div class="card mnrt-card mb-4">
        <div class="card-header mnrt-card-header">
          <h3>{{ cardHeader }}</h3>
        </div>
        <div class="card-body">
          <h4 class="card-title">
            {{ productionOrder.prodMineratecIdentifier }}
          </h4>
          <h5 class="card-subtitle mb-3">
            <i
              style="font-size: 1.3rem"
              class="d-inline-block me-1 bi bi-box-seam"
            ></i>
            {{ productionOrder.prodQuantity }}
          </h5>
          <router-link
            :to="{
              name: 'ProductList',
              params: { productionOrderId: productionOrder._id },
            }"
            class="card-link btn btn-outline-dark"
          >
            <i class="bi-list-nested"></i>
          </router-link>
          <button
            class="card-link btn btn-outline-danger"
            @click="deleteProductionOrder"
          >
            <i class="bi-x-circle"></i>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import i18n from '../i18n/i18n';
import viewHelper from '../view_helpers/app_view_helper';
import FullscreenModal from '../components/FullscreenModal';
import store from '../store/store';
import productionOrderDao from '../dao/production_order_dao';

export default {
  name: 'ProductionOrderCard',
  components: {
    FullscreenModal,
  },
  props: {
    productionOrder: {
      type: Object,
      required: true,
    },
  },
  setup() {
    return { i18n, viewHelper };
  },
  created() {
    this.isDeleted = this.productionOrder.deletedAt != null;
  },
  data() {
    return {
      isDeleted: false,
      showConfirmDelete: false,
    };
  },
  computed: {
    cardHeader() {
      if (this.productionOrder.salesOrderErpIdentifier) {
        return `${this.productionOrder.erpIdentifier} (${this.productionOrder.salesOrderErpIdentifier})`;
      } else {
        return this.productionOrder.erpIdentifier;
      }
    },
  },
  methods: {
    deleteProductionOrder() {
      this.showConfirmDelete = true;
    },
    handleModalCompleted(confirmed) {
      this.showConfirmDelete = false;

      if (confirmed) {
        store.commit('activateFullscreenOverlay');
        productionOrderDao.deleteProductionOrder(this.productionOrder, () => {
          this.isDeleted = true;
          store.commit('deactivateFullscreenOverlay');
        });
      }
    },
  },
};
</script>
