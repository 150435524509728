<template>
  <div class="row justify-content-center">
    <div class="col col-md-6">
      <div class="card mnrt-card mb-4">
        <div class="card-header mnrt-card-header">
          <h3>{{ viewHelper.formatDateTime(qi.createdAt) }}</h3>
        </div>
        <div class="card-body">
          <h4 class="card-title">
            Inspecionado por: {{ qi.inspectorFullName }}
          </h4>
          <h5 :class="hasPassedInspectionClasses">{{ hasPassedInspection }}</h5>
          <router-link
            :to="{
              name: 'ShowQualityInspection',
              params: { qualityInspectionId: qi._id },
            }"
            class="card-link btn btn-outline-dark"
          >
            <i class="bi bi-file-text"></i>
          </router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import i18n from '../i18n/i18n';
import viewHelper from '../view_helpers/app_view_helper';

export default {
  name: 'QualityInspectionCard',
  props: {
    qi: {
      type: Object,
      required: true,
    },
  },
  setup() {
    return { i18n, viewHelper };
  },
  data() {
    return {};
  },
  computed: {
    hasPassedInspection() {
      return this.qi.passed ? 'Aprovado' : 'Reprovado';
    },
    hasPassedInspectionClasses() {
      return this.qi.passed ? 'text-success' : 'text-danger';
    },
  },
};
</script>
