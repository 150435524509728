import store from '../store/store';

class Authorization {
  constructor() {
    this.adminViews = [];
  }

  isAdmin(user) {
    if (user === null) return false;

    return user.appRoles.includes('admin');
  }

  isAuthorized(user, viewName) {
    // In the future, we may have other roles and also admin only views.
    if (this.adminViews.includes(viewName)) return this.isAdmin(user);

    return true;
  }
}

export default new Authorization();
