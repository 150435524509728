<template>
  <div class="my-4">
    <slot name="body"></slot>
    <button
      class="d-block mt-4 me-4 mnrt-btn btn btn-outline-success"
      style="min-width: 195px"
      @click="confirmAction"
    >
      {{ i18n.t('actions.continueSave') }}
    </button>
    <button
      class="d-block mt-3 btn btn-outline-dark"
      style="min-width: 195px"
      @click="cancelAction"
    >
      {{ i18n.t('actions.amend') }}
    </button>
  </div>
</template>

<script>
import i18n from '../i18n/i18n';

export default {
  name: 'SummarizeAndConfirmDialog',
  emits: ['dialogCompleted'],
  setup() {
    return {
      i18n,
    };
  },
  data() {
    return {};
  },
  methods: {
    confirmAction() {
      this.$emit('dialogCompleted', true);
    },
    cancelAction() {
      this.$emit('dialogCompleted', false);
    },
  },
};
</script>
