<template>
  <div class="container-fluid">
    <div class="row justify-content-center">
      <div class="col col-md-6">
        <h1 class="mt-3">Ordem de produção</h1>
        <form class="my-4" @submit.prevent="submitAction" novalidate>
          <label for="sales-order-identifier-input" class="form-label">Pedido de venda (Nomus)</label>
          <input type="text" id="sales-order-identifier-input" class="form-control" v-model="salesOrderIdentifier" />
          <div class="text-danger">{{ salesOrderIdentifierError }}</div>

          <label for="production-order-identifier-input" class="form-label mt-4">* Ordem de produção (Nomus)</label>
          <input type="text" id="production-order-identifier-input" class="form-control" v-model="prodOrderIdentifier" />
          <div class="text-danger">{{ prodOrderIdentifierError }}</div>

          <label for="product-mineratec-identifier-input" class="form-label mt-4">* Código Mineratec</label>
          <input type="text" id="product-mineratec-identifier-input" class="form-control"
            v-model="prodMineratecIdentifier" />
          <div class="text-danger">{{ prodMineratecIdentifierError }}</div>

          <label for="product-qty-input" class="form-label mt-4">* Quantidade</label>
          <input type="text" id="product-qty-input" class="form-control" v-model="prodQty" />
          <div class="text-danger">{{ prodQtyError }}</div>

          <div class="form-check form-switch mnrt-form-switch mt-4">
            <input class="form-check-input cursor-pointer" id="product-uses-nfc-tag" type="checkbox" value="true"
              v-model="prodUsesNfcTag" />
            <label class="form-check-label">Usa tag NFC?</label>
          </div>
          <div class="text-danger">{{ prodUsesNfcTagError }}</div>

          <button class="my-4 btn btn-success">Salvar</button>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import QaDao from '../dao/qa_dao';
import productionOrderDao from '../dao/production_order_dao';
import productDao from '../dao/product_dao';
import i18n from '../i18n/i18n';
import store from '../store/store';
import { useToast } from 'vue-toastification';

export default {
  name: 'CreateProductionOrder',
  setup() {
    return { toast: useToast() };
  },
  async created() {
    this.nfcTagMandatoryProductsList = await new QaDao().getListById('mandatoryNfcTagProducts');
  },
  data() {
    return {
      nfcTagMandatoryProductsList: [],
      salesOrderIdentifier: '',
      salesOrderIdentifierError: '',
      prodOrderIdentifier: '',
      prodOrderIdentifierError: '',
      prodMineratecIdentifier: '',
      prodMineratecIdentifierError: '',
      prodQty: '',
      prodQtyError: '',
      prodUsesNfcTag: true,
      prodUsesNfcTagError: '',
    };
  },
  methods: {
    normalizeFields() {
      this.salesOrderIdentifier = this.salesOrderIdentifier
        .replaceAll(/\s+/g, '')
        .toUpperCase();

      this.prodOrderIdentifier = this.prodOrderIdentifier
        .replaceAll(/\s+/g, '')
        .toUpperCase();

      this.prodMineratecIdentifier = this.prodMineratecIdentifier
        .replaceAll(/\s+/g, '')
        .toUpperCase();
    },
    clearPreviousErrors() {
      this.salesOrderIdentifierError = '';
      this.prodOrderIdentifierError = '';
      this.prodMineratecIdentifierError = '';
      this.prodQtyError = '';
      this.prodUsesNfcTagError = '';
    },
    isNfcMandatory() {
      // If the Mineratec identifier is invalid, we cannot assess if the usage of a nfc tag
      // is mandatory
      if (!this.prodMineratecIdentifier || this.prodMineratecIdentifierError)
        return false;

      const firstSegmentMineratecIdentifier = this.prodMineratecIdentifier.split('-')[0];
      return this.nfcTagMandatoryProductsList.includes(firstSegmentMineratecIdentifier.toLowerCase());
    },
    validate() {
      this.clearPreviousErrors();
      this.normalizeFields();

      const salesOrderIdentifierRe = /^PV\d{4,}$/;
      if (
        this.salesOrderIdentifier != '' &&
        !salesOrderIdentifierRe.test(this.salesOrderIdentifier)
      )
        this.salesOrderIdentifierError = i18n.translate(
          'validations.salesOrder.erpIdentifier'
        );

      const prodOrderIdentifierRe = /^OS\d+-\d{2}$/;
      if (!prodOrderIdentifierRe.test(this.prodOrderIdentifier))
        this.prodOrderIdentifierError = i18n.translate(
          'validations.productionOrder.erpIdentifier'
        );

      const prodMineratecIdentifierRe = /^[A-Z]+-.+\d+$/;
      if (!prodMineratecIdentifierRe.test(this.prodMineratecIdentifier))
        this.prodMineratecIdentifierError = i18n.translate(
          'validations.productionOrder.prodMineratecIdentifier'
        );

      if (
        !(
          Number.isInteger(Number.parseInt(this.prodQty)) &&
          Number.parseInt(this.prodQty) > 0
        )
      )
        this.prodQtyError = i18n.translate('validations.shared.integerGtZero');

      if (!this.prodMineratecIdentifierError && this.isNfcMandatory() && !this.prodUsesNfcTag)
        this.prodUsesNfcTagError = i18n.translate('validations.productionOrder.prodUsesNfcTag');
    },
    submitAction() {
      this.validate();
      if (this.hasErrors) return;

      this.save();
    },
    save() {
      store.commit('activateFullscreenOverlay');

      let productionOrder = {
        salesOrderErpIdentifier: this.salesOrderIdentifier,
        erpIdentifier: this.prodOrderIdentifier,
        prodMineratecIdentifier: this.prodMineratecIdentifier,
        prodQuantity: this.prodQty,
        allProductsPassed: false,
        allNfcTagsRecorded: false,
        prodUseNfcTag: this.prodUsesNfcTag
      };
      productionOrderDao.saveProductionOrder(
        productionOrder,
        (productionOrderId) => {
          let product = {
            mineratecIdentifier: this.prodMineratecIdentifier,
            usesNfcTag: this.prodUsesNfcTag
          };
          productDao.saveProducts(
            this.prodOrderIdentifier,
            this.prodQty,
            productionOrderId,
            product,
            () => {
              store.commit('deactivateFullscreenOverlay');
              this.toast.success(i18n.translate('actions.saved'));
              this.$router.push({
                name: 'ProductionOrderList',
              });
            }
          );
        }
      );
    },
  },
  computed: {
    hasErrors() {
      return (
        [
          this.salesOrderIdentifierError,
          this.prodOrderIdentifierError,
          this.prodMineratecIdentifierError,
          this.prodQtyError,
          this.prodUsesNfcTagError
        ].filter((el) => el !== '').length > 0
      );
    },
  },
};
</script>
