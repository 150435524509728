<template>
  <div class="row justify-content-center">
    <div class="col col-md-6">
      <div class="card mnrt-card mb-4">
        <div class="card-header mnrt-card-header">
          <h3>{{ cardHeader }}</h3>
        </div>
        <div class="card-body">
          <h4 class="card-title">
            {{ product.mineratecIdentifier }}
          </h4>
          <div v-if="productPassedInspection" class="mt-3">
            <p>
              <i style="font-size: 1.3rem" class="bi bi-award-fill text-success"></i>
              Aprovado na inspeção
            </p>
          </div>
          <div v-if="!product.usesNfcTag" class="mt-3">
            <p>
              <i style="font-size: 1.3rem" class="bi bi-dash-square-dotted text-success"></i>
              Não usa tag NFC
            </p>
          </div>
          <div v-if="product.nfcTagRecorded" class="mt-3">
            <p>
              <i style="font-size: 1.3rem" class="bi bi-tag-fill text-success"></i>
              Tag NFC gravada
            </p>
          </div>
          <router-link :to="{
            name: 'QualityInspectionList',
            params: { productId: product._id },
          }" class="card-link btn btn-outline-dark">
            <i class="bi bi-journals"></i>
          </router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import i18n from '../i18n/i18n';
import viewHelper from '../view_helpers/app_view_helper';
import ProductHelper from '../helpers/product_helper';
import qualityInspectionDao from '../dao/quality_inspection_dao';

export default {
  name: 'ProductCard',
  props: {
    product: {
      type: Object,
      required: true,
    },
  },
  setup() {
    return { i18n, viewHelper };
  },
  created() {
    this.checkIfPassedInspection();
    qualityInspectionDao.getObjById(
      this.product.productionOrderId,
      (prodOrder) => {
        this.prodOrder = prodOrder;
      }
    );
  },
  data() {
    return {
      productPassedInspection: false,
      prodOrder: null,
    };
  },
  computed: {
    cardHeader() {
      if (this.prodOrder && this.prodOrder.salesOrderErpIdentifier) {
        return `${this.product.qaIdentifier} (${this.prodOrder.salesOrderErpIdentifier})`;
      } else {
        return this.product.qaIdentifier;
      }
    },
  },
  methods: {
    checkIfPassedInspection() {
      qualityInspectionDao.getProductQualityInspections(
        this.product._id,
        (qis) => {
          this.productPassedInspection = ProductHelper.hasPassedInspection(qis);
        }
      );
    },
  },
};
</script>
