<template>
  <FullscreenOverlay />
  <Navbar />
  <!--
    Having the key causes Vue to rerender the whole view when the URL changes.
    It is a trade-off between performance and how easy it is to implement / mantain
    code. For example, one can navigate to the Sync view passing different query string
    params. With this approach, we are certain that the view is going to be rerendered
    from scratch every time, avoiding issues caused by it maintaining part of the state
    used in a previous interaction.
    See: https://stackoverflow.com/questions/52505126/vue-router-component-reusing
  -->
  <router-view :key="$route.fullPath" />
</template>

<script>
import store from './store/store';
import session from './auth/session';
import { Authorization } from './auth/authorization';
import FullscreenOverlay from './components/FullscreenOverlay';
import Navbar from './components/Navbar';

export default {
  name: 'App',
  components: {
    FullscreenOverlay,
    Navbar,
  },
  data() {
    return {};
  },
};
</script>
