import { v4 as uuidv4 } from 'uuid';
import WorkspaceDao from './workspace_dao';
import ProductHelper from '../helpers/product_helper';

class ProductDao extends WorkspaceDao {
  constructor() {
    super();
  }

  async getProducts(productionOrderId, done) {
    try {
      const res = await this.db().find({
        selector: {
          productionOrderId: {
            '$eq': productionOrderId,
          },
        },
        use_index: 'idx_production_order_id',
        limit: 99999,
      });

      const products = res.docs
        .filter((product) => product._id.startsWith('product::'))
        .sort((a, b) => {
          const aProdSeq = Number(
            ProductHelper.qaIdentifierProductSequencePart(a.qaIdentifier)
          );
          const bProdSeq = Number(
            ProductHelper.qaIdentifierProductSequencePart(b.qaIdentifier)
          );

          if (aProdSeq > bProdSeq) return 1; // b should go before a
          if (aProdSeq < bProdSeq) return -1; // a should go before b

          return 0; // keep original order
        });

      done(products);
    } catch (err) {
      console.log(err);
    }
  }

  saveProducts(
    productionOrderErpIdentifier,
    qty,
    productionOrderId,
    product,
    done
  ) {
    let products = [];

    for (let i = 0; i < qty; i++) {
      let productInstance = {};
      Object.assign(productInstance, product, {
        _id: `product::${uuidv4()}`,
        qaIdentifier: `${productionOrderErpIdentifier}/${i + 1}`,
        productionOrderId: productionOrderId,
        nfcTagRecorded: false,
      });
      products.push(productInstance);
    }

    this.saveMultipleObjs(products, done);
  }
}

export default new ProductDao();
