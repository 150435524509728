import { newRecordDefaultFields, existingRecordDefaultFields } from './utils';
import dataTypes from '../data_types/data_types';
import store from '../store/store';

export default class Dao {
  async getObjById(id, done) {
    try {
      const obj = await this.db().get(id);

      // In case "done" was not passed in, the caller is async
      // and we can just return "obj".
      if (arguments.length === 1)
        return obj;

      done(obj);
    } catch (err) {
      console.log(err);
    }
  }

  async getListById(listId) {
    try {
      const list = await this.db().get(`list::${listId}`);
      return list.values;
    } catch (err) {
      console.log(err);
      return [];
    }
  }

  async saveObj(obj, done) {
    try {
      dataTypes.coerceRecursively(obj);
      newRecordDefaultFields(obj);
      await this.db().put(obj);
      done(obj._id);
    } catch (err) {
      console.log(err);
    }
  }

  async getObjsByKeyPrefix(startKey, done) {
    try {
      let res = await this.db().allDocs({
        include_docs: true,
        startkey: startKey,
        endkey: `${startKey}\ufff0`, // prefix search in CouchDB
      });

      done(res.rows.map((el) => el.doc));
    } catch (err) {
      console.log(err);
    }
  }

  async saveMultipleObjs(objs, done) {
    try {
      for (const obj of objs) {
        dataTypes.coerceRecursively(obj);
        newRecordDefaultFields(obj);
        await this.db().put(obj);
      }

      done();
    } catch (err) {
      console.log(err);
    }
  }

  async updateObj(obj, done) {
    try {
      dataTypes.coerceRecursively(obj);
      existingRecordDefaultFields(obj);
      await this.db().put(obj);
      done(obj._id);
    } catch (err) {
      console.log(err);
    }
  }

  async deleteMultipleObjs(objs, done) {
    try {
      for (const obj of objs) {
        dataTypes.coerceRecursively(obj);
        const now = new Date().toISOString();
        obj.updatedAt = now;
        obj.updatedBy = store.state.user.username;
        obj.updatedByFullName = store.state.user.fullName;
        obj.deletedAt = obj.updatedAt;

        await this.db().put(obj);
      }

      done();
    } catch (err) {
      console.log(err);
    }
  }
}
