<template>
  <div class="container-fluid">
    <div class="row justify-content-center">
      <div class="col col-md-6">
        <h1 class="my-3">Produtos da {{ productionOrder.erpIdentifier }}</h1>
        <router-link
          :to="{
            name: 'ProductionOrderList',
          }"
          class="mb-3 btn btn-outline-dark"
        >
          <i class="bi-arrow-left"></i>
          Voltar para ordens de produção
        </router-link>
      </div>
    </div>
    <ProductCard
      v-for="product in products"
      :key="product._id"
      :product="product"
    />
  </div>
</template>

<script>
import ProductCard from '../components/ProductCard';
import dao from '../dao/product_dao';

export default {
  name: 'ProductList',
  components: {
    ProductCard,
  },
  props: {
    productionOrderId: {
      type: String,
      required: true,
    },
  },
  created() {
    this.getProductionOrder();
    this.getProducts();
  },
  data() {
    return {
      productionOrder: {
        erpIdentifier: ''
      },
      products: [],
    };
  },
  methods: {
    getProductionOrder() {
      dao.getObjById(this.productionOrderId, (productionOrder) => {
        this.productionOrder = productionOrder;
      });
    },
    getProducts() {
      dao.getProducts(this.productionOrderId, (products) => {
        this.products = products;
      });
    },
  },
};
</script>
