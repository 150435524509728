<template>
  <div v-show="isActive" class="help-modal">
    <div class="close">
      <button
        type="button"
        class="btn btn-outline-dark cursor-pointer"
        @click="close">
        <i class="bi-x-lg"></i>
      </button>
    </div>
    <div class="body">
      <i>Instruções extras de como realizar essa verificação:</i>
      <br /><br />
      {{ helpText }}
    </div>
  </div>
</template>

<script>
  import i18n from '../i18n/i18n';

  export default {
    name: 'ChecklistItemHelpModal',
    props: {
      helpText: {
        type: String,
        default: ''
      }
    },
    setup() {
      return {
        i18n,
      };
    },
    data() {
      return {
        isActive: false,
      };
    },
    methods: {
      open() {
        this.isActive = true;
      },
      close() {
        this.isActive = false;
      },
    }
  };
</script>
