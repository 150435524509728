export default class ChecklistHelper {
  static checklistGroupedBySection(checklist, itemIdentifiersToInclude) {
    const shouldIncludeAllItems = itemIdentifiersToInclude === undefined;

    const checklistGroupedBySection = {};
    for (const itemIdentifier in checklist.items) {
      if (
        shouldIncludeAllItems ||
        itemIdentifiersToInclude.includes(itemIdentifier)
      ) {
        const item = checklist.items[itemIdentifier];

        if (checklistGroupedBySection[item.section] === undefined)
          checklistGroupedBySection[item.section] = [];

        checklistGroupedBySection[item.section].push({
          text: item.text,
          value: itemIdentifier,
          help: item.help,
        });
      }
    }

    return checklistGroupedBySection;
  }
}
