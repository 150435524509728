<template>
  <div class="container-fluid">
    <div class="row justify-content-center">
      <div v-if="loaded" class="col col-md-6">
        <h1 class="mt-3">Inspeção de qualidade</h1>
        <h4 class="mt-3">{{ product.qaIdentifier }}</h4>
        <h5 class="mt-3">{{ product.mineratecIdentifier }}</h5>
        <form
          v-show="!showSummarizeAndConfirmDialog"
          class="my-4"
          @submit.prevent="submitAction"
          novalidate
        >
          <label for="qi-inspector-select" class="form-label"
            >Responsável pela inspeção</label
          >
          <select
            id="qi-inspector-select"
            class="form-select"
            v-model="qiInspector"
          >
            <option
              v-for="inspector in inspectors"
              :key="inspector.value"
              :value="inspector.value"
            >
              {{ inspector.text }}
            </option>
          </select>
          <div class="text-danger">{{ qiInspectorError }}</div>

          <label class="form-label mt-4 text-uppercase">Etiquetagem</label>
          <div class="form-check form-switch mnrt-form-switch">
            <input
              class="form-check-input cursor-pointer"
              id="qi-is-labeled"
              type="checkbox"
              value="true"
              v-model="qiIsLabeled"
            />
            <label class="form-check-label"
              >Confirmo que etiquetei o produto</label
            >
          </div>
          <div class="text-danger">{{ qiIsLabeledError }}</div>

          <label class="form-label mt-4 text-uppercase d-block"
            >Checklist</label
          >
          <div
            v-for="(sectionItems, section, index) in checklistGroupedBySection"
            :key="`vue_checklist_section_${index}`"
          >
            <label class="form-label mt-4 text-uppercase d-block">{{
              section
            }}</label>
            <div
              v-for="checklistItem in sectionItems"
              :key="`vue_checklist_item_${checklistItem.value}`"
              class="form-check form-switch mnrt-form-switch mt-4"
            >
              <input
                class="form-check-input cursor-pointer"
                :id="`checklist_item_${checklistItem.value}`"
                type="checkbox"
                :value="checklistItem.value"
                v-model="qiChecklist"
              />
              <label
                class="form-check-label"
              >
                <span class="d-inline-block me-2">{{ checklistItem.text }}</span>
                <i
                  v-if="checklistItem.help"
                  class="bi-question-square cursor-pointer"
                  @click="showChecklistItemHelp(checklistItem)"
                >
                </i>
              </label>
            </div>
          </div>

          <label for="qi-obs-text-area" class="mt-4 form-label"
            >Observaçōes adicionais</label
          >
          <textarea
            id="qi-obs-text-area"
            class="form-control"
            rows="5"
            v-model="qiObs"
          ></textarea>

          <label class="form-label mt-4 text-uppercase">Fotos</label>
          <Camera ref="camera" idleText="Tirar fotos do produto" />

          <div v-show="hasErrors" class="text-danger">
            {{ i18n.t('validations.shared.formErrorsSummary') }}
          </div>
          <button type="submit" class="my-4 btn btn-success">Salvar</button>
        </form>
        <SummarizeAndConfirmDialog
          v-show="showSummarizeAndConfirmDialog"
          @dialogCompleted="handleDialogCompleted"
        >
          <template v-slot:body>
            <div v-show="!hasPhotos()" class="alert alert-warning">
              É fortemente recomendável que você adicione algumas fotos para
              documentar a inspeção do produto.
            </div>
            <div v-if="hasPassedInspection">
              <p>
                Você está <b class="text-success">aprovando</b> esse produto na
                inspeção de qualidade.
              </p>
              <p>É isso mesmo que deseja fazer?</p>
            </div>
            <div v-else>
              <p>
                Você está <b class="text-danger">reprovando</b> esse produto na
                inspeção de qualidade.
              </p>
              <p>
                O produto <b class="text-danger">falhou</b> nos seguintes itens
                de inspeção:
              </p>
              <div
                v-for="(
                  sectionItems, section, index
                ) in failedChecklistGroupedBySection"
                :key="`failed_checklist_section_${index}`"
              >
                <label class="form-label mt-4 text-uppercase d-block">{{
                  section
                }}</label>
                <ul class="d-block my-4 list-unstyled">
                  <li
                    v-for="(failedChecklistItem, index) in sectionItems"
                    :key="`failed_checklist_item_${index}`"
                  >
                    <i class="bi bi-x-octagon"></i>
                    {{ failedChecklistItem.text }}
                  </li>
                </ul>
              </div>
              <p>É isso mesmo que deseja fazer?</p>
            </div>
          </template>
        </SummarizeAndConfirmDialog>
        <ChecklistItemHelpModal :helpText="currentChecklistItemHelpText" ref="helpModal" />
      </div>
    </div>
  </div>
</template>

<script>
import Camera from '../components/Camera';
import QaDao from '../dao/qa_dao';
import productDao from '../dao/product_dao';
import productionOrderDao from '../dao/production_order_dao';
import checklistDao from '../dao/checklist_dao';
import qualityInspectionDao from '../dao/quality_inspection_dao';
import attachmentDao from '../dao/attachment_dao';
import SummarizeAndConfirmDialog from '../components/SummarizeAndConfirmDialog';
import i18n from '../i18n/i18n';
import store from '../store/store';
import { useToast } from 'vue-toastification';
import ChecklistHelper from '../helpers/checklist_helper';
import ChecklistItemHelpModal from '../components/ChecklistItemHelpModal';

export default {
  name: 'QualityInspection',
  components: {
    Camera,
    SummarizeAndConfirmDialog,
    ChecklistItemHelpModal,
  },
  props: {
    productId: {
      required: true,
      type: String,
    },
  },
  setup() {
    return { i18n, toast: useToast() };
  },
  created() {
    productDao.getObjById(this.productId, (product) => {
      this.product = product;

      const qaDao = new QaDao();
      qaDao.getObjById('collection::inspectors', (inspectors) => {
        this.inspectors = inspectors.textAndValues;

        checklistDao.getAppropriateChecklist(
          this.product.mineratecIdentifier,
          (checklist) => {
            this.checklist = checklist;

            productionOrderDao.getObjById(
              this.product.productionOrderId,
              (productionOrder) => {
                this.productionOrder = productionOrder;

                this.loaded = true;
              }
            );
          }
        );
      });
    });
  },
  data() {
    return {
      loaded: false,
      inspectors: [],
      checklist: null,
      product: { mineratecIdentifier: '', qaIdentifier: '' },
      showChecklistItemHelpModal: false,
      showSummarizeAndConfirmDialog: false,
      currentChecklistItemHelpText: '',
      qiInspector: null,
      qiInspectorError: '',
      qiIsLabeled: false,
      qiIsLabeledError: '',
      qiChecklist: [],
      qiObs: '',
    };
  },
  methods: {
    hasPhotos() {
      if (!this.$refs.camera) return false;
      return this.$refs.camera.anyFiles();
    },
    submitAction() {
      this.validate();
      if (this.hasErrors) return;

      this.showSummarizeAndConfirmDialog = true;
    },
    clearPreviousErrors() {
      this.qiInspectorError = '';
      this.qiIsLabeledError = '';
    },
    validate() {
      this.clearPreviousErrors();

      if (this.qiInspector === null || this.qiInspector === '')
        this.qiInspectorError = i18n.translate(
          'validations.shared.mustBeSelected'
        );

      if (!this.qiIsLabeled)
        this.qiIsLabeledError = i18n.translate(
          'validations.qualityInspection.productRequiresLabeling'
        );
    },
    showChecklistItemHelp(checklistItem) {
      this.currentChecklistItemHelpText = checklistItem.help;
      this.$refs.helpModal.open();
    },
    handleDialogCompleted(confirmed) {
      if (confirmed) {
        this.save();
      } else {
        this.showSummarizeAndConfirmDialog = false;
      }
    },
    save() {
      store.commit('activateFullscreenOverlay');

      const qi = {
        productId: this.productId,
        checklistId: this.checklist._id,
        inspector: this.qiInspector,
        inspectorFullName: this.qiInspectorFullName,
        passed: this.hasPassedInspection,
        passedChecklistItems: this.qiChecklist,
        failedChecklistItems: this.failedChecklistItemIdentifiers,
        observations: this.qiObs,
      };
      qualityInspectionDao.saveQualityInspection(qi, (qiId) => {
        if (this.hasPhotos()) {
          const metadata = {
            ownerDocId: qiId,
          };
          this.$refs.camera.waitForOptimizedFiles((files) => {
            attachmentDao.saveAttachments(metadata, files, this.afterSave);
          });
        } else {
          this.afterSave();
        }
      });
    },
    afterSave() {
      productionOrderDao.checkIfAllProductsPassed(this.productionOrder, () => {
        store.commit('deactivateFullscreenOverlay');
        this.toast.success(i18n.translate('actions.saved'));
        this.$router.push({
          path: '/',
        });
      });
    },
  },
  computed: {
    hasErrors() {
      return (
        [this.qiInspectorError, this.qiIsLabeledError].filter((el) => el !== '')
          .length > 0
      );
    },
    qiInspectorFullName() {
      if (!this.qiInspector) return '';
      return this.inspectors.find(
        (inspector) => inspector.value === this.qiInspector
      ).text;
    },
    checklistGroupedBySection() {
      return ChecklistHelper.checklistGroupedBySection(this.checklist);
    },
    checklistItemIdentifiers() {
      return Object.keys(this.checklist.items).sort();
    },
    hasPassedInspection() {
      if (this.qiChecklist.length === 0) return false;

      return (
        [...this.qiChecklist].sort().toString() ===
        this.checklistItemIdentifiers.toString()
      );
    },
    failedChecklistItemIdentifiers() {
      return this.checklistItemIdentifiers.filter(
        (itemIdentifier) => !this.qiChecklist.includes(itemIdentifier)
      );
    },
    failedChecklistGroupedBySection() {
      return ChecklistHelper.checklistGroupedBySection(
        this.checklist,
        this.failedChecklistItemIdentifiers
      );
    },
  },
};
</script>
