import { qaDb } from '../db/db';
import Dao from './dao';

export default class QaDao extends Dao {
  constructor() {
    super();
  }

  db() {
    return qaDb.db();
  }

  createIndexes() {
    return Promise.resolve(); // Dummy promise that automatically resolves.
  }
}
