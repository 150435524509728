<template>
  <div v-if="loaded" class="container-fluid">
    <div class="row justify-content-center">
      <div class="col col-md-6">
        <h1 class="mt-3">Gravar tag NFC</h1>
        <h4 class="mt-3">{{ product.qaIdentifier }}</h4>
        <h5 class="mt-3">{{ product.mineratecIdentifier }}</h5>
        <template v-if="webNfcSupported">
          <button
            v-show="
              recordingStatus === 'waiting' || recordingStatus === 'failure'
            "
            class="mt-5 btn btn-success"
            @click.stop="startNfcRecording"
          >
            Iniciar gravação
          </button>
          <p v-show="recordingStatus === 'failure'" class="mt-3 text-danger">
            A gravação da etiqueta falhou. Por favor, tente novamente.<br />
            Se o problema persistir, contate o suporte.
          </p>
          <div v-show="recordingStatus === 'started'" class="mt-5">
            <p>Aproxime a tag do sensor do celular...</p>
            <div
              class="d-block spinner-grow text-success"
              style="width: 125px; height: 125px"
            ></div>
          </div>
          <p
            v-show="recordingStatus === 'success'"
            class="mt-5 fw-bold text-success"
          >
            Etiqueta gravada com sucesso!
          </p>
        </template>
        <template v-else>
          <p class="mt-5 fw-bold text-warning">
            Ops... Parece que o seu dispositivo não suporta a leitura / gravação
            de tags NFC.
          </p>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import productDao from '../dao/product_dao';
import productionOrderDao from '../dao/production_order_dao';

export default {
  name: 'WriteProductToNfc',
  props: {
    productId: {
      type: String,
      required: true,
    },
  },
  created() {
    this.webNfcSupported = 'NDEFReader' in window;

    productDao.getObjById(this.productId, (product) => {
      this.product = product;

      productionOrderDao.getObjById(
        this.product.productionOrderId,
        (productionOrder) => {
          this.productionOrder = productionOrder;
          this.ndef = new window.NDEFReader();

          this.loaded = true;
        }
      );
    });
  },
  data() {
    return {
      loaded: false,
      recordingStatus: 'waiting',
      webNfcSupported: false,
      ndef: false,
      product: null,
      productionOrder: null,
    };
  },
  methods: {
    // It is recommended to do a write inside a read event. By doing so, we achieve two objectives:
    // 1) We can first read the tag and, if necessary, abort the write operation;
    // 2) We can avoid triggering Android's standard NFC service (that would read the tag) right after writing to it.
    // The code below is based on the solution described at https://w3c.github.io/web-nfc/#handling-initial-reads-while-writing
    setupNfcReader() {
      this.ndef.addEventListener(
        'reading',
        (event) => {
          this.ndef
            .write(this.product.qaIdentifier)
            .then(() => this.updateObjects())
            .catch((error) => {
              this.recordingStatus = 'failure';
              console.log(error);
            });
        },
        { once: true }
      );
    },
    startNfcRecording() {
      this.recordingStatus = 'started';
      this.setupNfcReader();
      this.ndef.scan();
    },
    updateObjects() {
      this.product.nfcTagRecorded = true;
      productDao.updateObj(this.product, (_productId) => {
        productionOrderDao.checkIfAllNfcTagsWereRecorded(
          this.productionOrder,
          (_productionOrderId) => {
            this.recordingStatus = 'success';
          }
        );
      });
    },
  },
};
</script>
