<template>
  <file-pond
    name="camera"
    ref="pond"
    accepted-file-types="image/png, image/jpeg, image/gif"
    credits="false"
    :label-idle="labelIdle"
    allow-multiple="true"
    allow-drop="false"
    allow-browse="true"
    :capture-method="null"
    required="false"
    :files="files"
    @init="handleFilePondInit"
    :beforeAddFile="handleBeforeAddFile"
    :beforeRemoveFile="handleBeforeRemoveFile"
  />
</template>

<script>
import i18n from '../i18n/i18n';
import store from '../store/store';

import md5 from 'crypto-js/md5';
import skaler from 'skaler';
import Compressor from 'compressorjs';

import vueFilePond from 'vue-filepond';
import ptBr from 'filepond/locale/pt-br';
import 'filepond/dist/filepond.min.css';
import '../assets/third_party/filepond_overrides.css';

import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type';
import FilePondPluginImagePreview from 'filepond-plugin-image-preview';

import 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.min.css';

const FilePond = vueFilePond(
  FilePondPluginFileValidateType,
  FilePondPluginImagePreview
);

export default {
  name: 'Camera',
  components: {
    FilePond,
  },
  props: {
    idleText: String,
  },
  data() {
    return {
      i18n: i18n,
      files: null,
      optimizationPending: {},
      optimizedFiles: {},
    };
  },
  methods: {
    handleFilePondInit() {},
    handleBeforeAddFile(pondFile) {
      const fileReader = new FileReader();
      fileReader.onload = (e) => {
        const imgMd5 = md5(e.target.result).toString();

        this.startImgOptimization(imgMd5, pondFile.file);
      };
      fileReader.readAsBinaryString(pondFile.file);

      return true;
    },
    handleBeforeRemoveFile(pondFile) {
      const fileReader = new FileReader();
      fileReader.onload = (e) => {
        const imgMd5 = md5(e.target.result).toString();

        this.waitForOptimizedFiles((_optimizedFiles) => {
          delete this.optimizedFiles[imgMd5];
          store.commit('deactivateFullscreenOverlay');
        });
      };
      store.commit('activateFullscreenOverlay');
      fileReader.readAsBinaryString(pondFile.file);

      return true;
    },
    startImgOptimization(imgMd5, file) {
      this.optimizationPending[imgMd5] = true;

      const img = new Image();
      img.onload = () => {
        img.width >= img.height
          ? this.performImgOptimization(imgMd5, file, { width: 1000 })
          : this.performImgOptimization(imgMd5, file, { width: 563 });
      };

      const fileReader = new FileReader();
      fileReader.onload = (e) => {
        img.src = e.target.result;
      };
      fileReader.readAsDataURL(file);
    },
    async performImgOptimization(imgMd5, file, optimizationOpts) {
      const resized = await skaler(file, optimizationOpts);

      new Compressor(resized, {
        quality: 0.75,
        success: (compressed) => {
          this.optimizedFiles[imgMd5] = compressed;
          delete this.optimizationPending[imgMd5];
        },
      });
    },
    anyFiles() {
      return this.$refs.pond.getFiles().length > 0;
    },
    anyOptimizationPending() {
      return Object.keys(this.optimizationPending).length > 0;
    },
    getFileBlobs() {
      return this.$refs.pond.getFiles().map((pondFile) => pondFile.file);
    },
    waitForOptimizedFiles(cb) {
      if (this.anyOptimizationPending()) {
        setTimeout(this.waitForOptimizedFiles(cb), 500);
      } else {
        cb(Object.values(this.optimizedFiles));
      }
    },
  },
  computed: {
    labelIdle() {
      if (this.idleText)
        return `<span class="filepond--label-action">${this.idleText}</span>`;
      else return this.i18n.translate('thirdParty.filepond.labelIdle');
    },
  },
};
</script>
