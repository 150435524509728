import { v4 as uuidv4 } from 'uuid';
import WorkspaceDao from './workspace_dao';
import { newRecordDefaultFields } from './utils';
import dataTypes from '../data_types/data_types';

class QualityInspectionDao extends WorkspaceDao {
  constructor() {
    super();
  }

  async getProductQualityInspections(productId, done) {
    try {
      let res = await this.db().find({
        selector: {
          productId: productId,
        },
        use_index: 'idx_product_id',
      });

      done(
        res.docs
          .filter((doc) => doc._id.startsWith('quality_inspection::'))
          .sort((a, b) => {
            // Descending sort, newest records will come first.
            if (a.createdAt > b.createdAt) return -1; // a should go before b
            if (a.createdAt < b.createdAt) return 1; // b should go before a

            return 0; // keep original order
          })
      );
    } catch (err) {
      console.log(err);
    }
  }

  async saveQualityInspection(qi, done) {
    try {
      const id = `quality_inspection::${uuidv4()}`;
      Object.assign(qi, {
        _id: id,
      });
      await this.saveObj(qi, done);
    } catch (err) {
      console.log(err);
    }
  }
}

export default new QualityInspectionDao();
