<template>
  <p class="fw-bold" :data-attachment-collection-id="attachmentCollectionId">
    {{ title }}
  </p>
  <div v-if="ready">
    <div>
      <div
        v-for="(file, i) in landscapeImgFiles"
        :key="`${i}-${file.digest}`"
        class="d-inline-block mb-3 me-3"
        style="max-width: 350px"
      >
        <img
          :src="`data:${file.content_type};base64,${file.data}`"
          style="break-inside: avoid"
          class="img-fluid rounded"
        />
      </div>
    </div>
    <div>
      <div
        v-for="(file, i) in portraitImgFiles"
        :key="`${i}-${file.digest}`"
        style="max-width: 228px; break-inside: avoid"
        class="d-inline-block mb-3 me-3"
      >
        <img
          :src="`data:${file.content_type};base64,${file.data}`"
          class="img-fluid rounded"
        />
      </div>
    </div>
  </div>
  <div v-else>
    <div class="spinner-border spinner-border-sm text-dark"></div>
    <p class="ms-2 d-inline-block">
      Baixando imagens desse evento... Por favor, aguarde, isso pode demorar
      alguns minutos...
    </p>
  </div>
</template>

<script>
export default {
  name: 'CouchDbImageGallery',
  props: {
    title: {
      type: String,
      required: true,
    },
    attachmentCollectionId: {
      type: String,
      required: true,
    },
    database: {
      type: Object,
      required: true,
    },
  },
  created() {
    this.downloadAttachments(true);
  },
  data() {
    return {
      imagesLoaded: false,
      attachments: {},
      landscapeImgFiles: [],
      portraitImgFiles: [],
    };
  },
  computed: {
    files() {
      return Object.values(this.attachments);
    },
    imagesProcessed() {
      return (
        this.files.length > 0 &&
        this.files.length ===
          this.landscapeImgFiles.length + this.portraitImgFiles.length
      );
    },
    ready() {
      return this.imagesLoaded && this.imagesProcessed;
    },
  },
  methods: {
    async downloadAttachmentsFromDb(attachmentIds, done) {
      try {
        let res = await this.database.db().allDocs({
          include_docs: true,
          attachments: true,
          keys: attachmentIds,
        });
        done(
          res.rows
            .filter(
              (el) => el.error !== 'not_found' && el.value.deleted !== true
            )
            .map((el) => el.doc)
        );
      } catch (err) {
        console.log(err);
      }
    },
    downloadAttachments(fetchOptimizedIfAvailable) {
      const attachmentCollectionId = fetchOptimizedIfAvailable
        ? `${this.attachmentCollectionId}::small`
        : this.attachmentCollectionId;

      this.downloadAttachmentsFromDb(
        [attachmentCollectionId],
        (attachmentCollectionWithAttachmentsArr) => {
          if (
            attachmentCollectionWithAttachmentsArr.length === 0 &&
            fetchOptimizedIfAvailable
          ) {
            // optimized images are not available; we try once more, but this time we attempt to fetch the original images.
            this.downloadAttachments(false);
            return;
          }

          const attachmentCollectionWithAttachments =
            attachmentCollectionWithAttachmentsArr[0];
          this.attachments = attachmentCollectionWithAttachments._attachments;
          this.imagesLoaded = true;
          this.groupImagesByOrientation();
        }
      );
    },
    groupImagesByOrientation() {
      this.files.forEach((file) => {
        const img = new Image();
        img.onload = () => {
          img.width >= img.height
            ? this.landscapeImgFiles.push(file)
            : this.portraitImgFiles.push(file);
        };
        img.src = `data:${file.content_type};base64,${file.data}`;
      });
    },
  },
};
</script>
