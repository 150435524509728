<template>
  <div v-if="newVersionInstalled" class="container-fluid">
    <div class="row justify-content-center">
      <div class="col col-md-6">
        <h1 class="mt-3">Nova versão instalada</h1>
        <p class="mt-3 text-success">
          Pronto, você já está usando a última versão disponível do QA.
        </p>
        <p class="my-3">
          Você já pode usar o menu para acessar qualquer tela e continuar usando
          o aplicativo normalmente.
        </p>
      </div>
    </div>
  </div>
  <div v-else class="container-fluid">
    <div class="row justify-content-center">
      <div class="col col-md-6">
        <h1 class="mt-3">Sincronizar com o servidor</h1>
        <button v-if="!signingOut" class="my-3 btn btn-success" :class="startSyncBtnClasses" @click="startSync">
          Iniciar sincronização
        </button>
        <p v-if="signingOut" class="mb-3 text-warning">
          Você será desconectado do sistema automaticamente assim que o processo
          de sincronização concluir.<br /><br />
          Caso ele falhe, basta tentar sair novamente do sistema para reiniciar
          a operação.<br /><br />
          Aguarde, isso pode demorar um pouco...<br />
        </p>
        <p class="mb-3" :class="globalSyncStatusMsgClasses">
          {{ globalSyncStatusMsg }}
        </p>
        <p class="mb-3" :class="dbMaintenanceStatusMsgClasses">
          {{ dbMaintenanceStatusMsg }}
        </p>
        <p class="mb-3" :class="checkVersionStatusMsgClasses">
          {{ checkVersionStatusMsg }}
        </p>
      </div>
    </div>
    <SyncPanel ref="qaDbSyncPanel" sync-mode="pull" :db="dbs.qaDb" :remoteDb="remoteDbs.qaDb"
      v-model:overall-sync-status="qaDbStatus" title="Dados do aplicativo" />
    <SyncPanel ref="workspaceDbSyncPanel" sync-mode="pullPush" :db="dbs.workspaceDb" :remoteDb="remoteDbs.workspaceDb"
      v-model:overall-sync-status="workspaceDbStatus" title="Dados de inspeção coletados" />
    <SyncPanel ref="workspaceAttachmentsDbSyncPanel" sync-mode="push" :db="dbs.workspaceAttachmentsDb"
      :remoteDb="remoteDbs.workspaceAttachmentsDb" v-model:overall-sync-status="workspaceAttachmentsDbStatus"
      title="Fotos" push-active-msg="Carregando fotos..." />
  </div>
</template>

<script>
import {
  RemoteDb,
  buildRemoteDbAddr,
  qaDb,
  workspaceDb,
  workspaceAttachmentsDb,
} from '../db/db';
import QaDao from '../dao/qa_dao';
import WorkspaceDao from '../dao/workspace_dao';
import attachmentDao from '../dao/attachment_dao';
import SyncPanel from '../components/SyncPanel';
import syncMixin from '../mixins/sync_mixin';
import session from '../auth/session';
import i18n from '../i18n/i18n';
import { useToast } from 'vue-toastification';

export default {
  name: 'Sync',
  components: {
    SyncPanel,
  },
  setup() {
    return { toast: useToast() };
  },
  created() {
    buildRemoteDbAddr('qa', (remoteDbAddr) => {
      this.remoteDbs.qaDb = new RemoteDb(remoteDbAddr);
    });
    buildRemoteDbAddr(
      'workspace',
      (remoteDbAddr) =>
        (this.remoteDbs.workspaceDb = new RemoteDb(remoteDbAddr))
    );
    buildRemoteDbAddr(
      'workspaceAttachments',
      (remoteDbAddr) =>
        (this.remoteDbs.workspaceAttachmentsDb = new RemoteDb(remoteDbAddr))
    );

    document.addEventListener('swUpdateFound', this.downloadNewVersion);
    document.addEventListener('swUpdated', this.installNewVersion);
    navigator.serviceWorker.addEventListener(
      'controllerchange',
      this.restartAfterInstallation
    );

    // There seems to be a delay propagating the remoteDb property
    // to the children SyncPanel components. The following techniques
    // did not solve the problem:
    // a) To have the startSync method called inside the last buildRemoteDbAddr
    //    callback (and to have each callback call the buildRemoteDbAddr function
    //    for the next database).
    // b) Do something similar to A, but setting remoteDb directly in each
    //    SyncPanel (using $refs).
    // Given that, unfortunately the only workaround I have found is to use setTimeout.
    if (this.signingOut) setTimeout(this.startSync, 3000);
  },
  mixins: [syncMixin],
  props: {
    newVersionInstalled: {
      type: Boolean,
      default: false,
    },
    signingOut: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      dbs: {
        qaDb,
        workspaceDb,
        workspaceAttachmentsDb,
      },
      remoteDbs: {
        qaDb: null,
        workspaceDb: null,
        workspaceAttachmentsDb: null,
      },
      qaDbStatus: 'waiting',
      workspaceDbStatus: 'waiting',
      workspaceAttachmentsDbStatus: 'waiting',
      dbMaintenanceStatus: 'waiting',
      swRegistration: null,
      checkVersionStatus: 'waiting',
      restarting: false,
    };
  },
  computed: {
    globalSyncStatus() {
      return this.coalesceSyncStatuses(
        this.qaDbStatus,
        this.workspaceDbStatus,
        this.workspaceAttachmentsDbStatus
      );
    },
    globalSyncStatusMsg() {
      switch (this.globalSyncStatus) {
        case 'waiting':
          return '';
        case 'working':
          return 'Sincronização: em progresso...';
        case 'error':
          return 'Sincronização: uma ou mais sincronizaçōes falharam. Por favor, tente novamente.';
        default:
          // this.globalSyncStatus is 'complete'
          return 'Sincronização: todos os dados foram sincronizados com sucesso.';
      }
    },
    startSyncBtnClasses() {
      if (this.globalSyncStatus === 'working') return 'disabled';
      else return '';
    },
    globalSyncStatusMsgClasses() {
      return this.syncStatusClasses(this.globalSyncStatus);
    },
    dbMaintenanceStatusMsg() {
      switch (this.dbMaintenanceStatus) {
        case 'waiting':
          return '';
        case 'working':
          return 'Otimização do banco de dados: em progresso...';
        default:
          // this.dbMaintenanceStatus is 'complete'
          return 'Otimização do banco de dados: concluída.';
      }
    },
    dbMaintenanceStatusMsgClasses() {
      if (this.dbMaintenanceStatus === 'complete') return 'text-success';
      else return '';
    },
    checkVersionStatusMsg() {
      switch (this.checkVersionStatus) {
        case 'waiting':
          return '';
        case 'checking':
          return 'QA: verificando se há uma nova versão...';
        case 'downloading':
          return 'QA: baixando a nova versão...';
        case 'upToDate':
          return 'QA: já estamos rodando a última versão disponível.';
        case 'error':
          return 'QA: falhamos em instalar a nova versão. Por favor, tente novamente.';
        default:
          // this.checkVersionStatus is 'installing'
          return 'QA: instalando a nova versão. A página irá recarregar em 3 segundos...';
      }
    },
    checkVersionStatusMsgClasses() {
      switch (this.checkVersionStatus) {
        case 'upToDate':
          return 'text-success';
        case 'error':
          return 'text-danger';
        default:
          return '';
      }
    },
  },
  watch: {
    globalSyncStatus(newSyncStatus, oldSyncStatus) {
      if (newSyncStatus === 'complete') {
        if (this.signingOut) this.completeSignOut();
        else {
          Promise.all(this.maintainDbs()).then(() => {
            this.dbMaintenanceStatus = 'complete';
            this.checkVersion();
          });
        }
      }
    },
  },
  methods: {
    startSync() {
      this.checkVersionStatus = 'waiting';
      this.$refs.qaDbSyncPanel.sync();
      this.$refs.workspaceDbSyncPanel.sync();
      this.$refs.workspaceAttachmentsDbSyncPanel.sync();
    },
    completeSignOut() {
      Promise.all([
        workspaceDb.db().destroy(),
        workspaceAttachmentsDb.db().destroy(),
        session.signOut(),
      ]).then(() => {
        this.toast.success(i18n.translate('actions.authSignedOut'));
        this.$router.push({
          name: 'SignIn',
        });
      });
    },
    maintainDbs() {
      this.dbMaintenanceStatus = 'working';
      return [
        // After uploading attachments, we destroy the local DB to avoid taking up too much space on the user's device.
        workspaceAttachmentsDb.db().destroy(),
        ...this.createIndexes(),
      ];
    },
    createIndexes() {
      return [
        new QaDao().createIndexes(),
        new WorkspaceDao().createIndexes(),
        attachmentDao.createIndexes(),
      ];
    },
    checkVersion() {
      this.checkVersionStatus = 'checking';
      navigator.serviceWorker
        .getRegistration()
        .then((registration) => registration.update())
        .then((registration) => {
          if (
            registration.waiting === null &&
            registration.installing === null
          ) {
            this.checkVersionStatus = 'upToDate';
          } else if (registration.waiting) {
            // A new version (i.e. service worker) was already downloaded
            // before the Sync view was accessed. Therefore, we can directly
            // go to the installation step.
            const event = { detail: registration };
            this.installNewVersion(event);
          }
        })
        .catch((err) => (this.checkVersionStatus = 'error'));
    },
    downloadNewVersion() {
      this.checkVersionStatus = 'downloading';
    },
    installNewVersion(customEvent) {
      this.swRegistration = customEvent.detail;
      this.checkVersionStatus = 'installing';
      setTimeout(() => {
        this.swRegistration.waiting.postMessage({ type: 'skipWaiting' });
      }, 3000);
    },
    restartAfterInstallation() {
      if (this.restarting) return;
      this.restarting = true;
      window.location.replace('/sync?newVersionInstalled=true');
    },
  },
};
</script>
