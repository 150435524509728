<template>
  <div v-if="isActive" class="fullscreen-modal">
    <div class="fullscreen-modal-content">
      <p class="fm-body d-block m-0">
        <slot name="body">{{
          i18n.t('components.fullscreenModal.bodyDefault')
        }}</slot>
      </p>
      <button
        class="d-inline-block mt-4 me-4 mnrt-btn btn btn-danger"
        style="min-width: 150px"
        @click="confirmAction"
      >
        <slot name="confirmBtn">
          {{ i18n.t('components.fullscreenModal.confirmActionDefault') }}
        </slot>
      </button>
      <button
        class="d-inline-block mt-4 mnrt-btn btn btn-outline-dark"
        style="min-width: 150px"
        @click="cancelAction"
      >
        <slot name="cancelBtn">
          {{ i18n.t('components.fullscreenModal.cancelActionDefault') }}
        </slot>
      </button>
    </div>
  </div>
</template>

<script>
import i18n from '../i18n/i18n';

export default {
  name: 'FullscreenModal',
  emits: ['modalCompleted'],
  props: {
    isActive: {
      type: Boolean,
      default: false,
    },
  },
  setup() {
    return {
      i18n,
    };
  },
  data() {
    return {};
  },
  methods: {
    confirmAction() {
      this.$emit('modalCompleted', true);
    },
    cancelAction() {
      this.$emit('modalCompleted', false);
    },
  },
};
</script>
