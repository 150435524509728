import QaDao from './qa_dao';

class ChecklistDao extends QaDao {
  constructor() {
    super();
  }

  async getAppropriateChecklist(
    mineratecIdentifier,
    done,
    loadFallbackIfChecklistMissing = false
  ) {
    try {
      const id = `checklist::${mineratecIdentifier}`.toLowerCase();
      const res = await this.db().get(id);
      done(res);
    } catch (err) {
      if (err.name === 'not_found') {
        if (loadFallbackIfChecklistMissing) {
          this.getObjById('checklist::default', done);
        } else {
          const familyIdentifier = mineratecIdentifier.split('-')[0];
          this.getAppropriateChecklist(familyIdentifier, done, true);
        }
      } else {
        console.log(err);
      }
    }
  }
}

export default new ChecklistDao();
