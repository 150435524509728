import PouchDb from 'pouchdb';
import store from '../store/store';

class Session {
  db() {
    return new PouchDb('qa_session');
  }

  currentUser() {
    return this.db().get('session');
  }

  loadIntoStore() {
    return this.currentUser().then((user) => store.commit('loadUser', user));
  }

  signOut() {
    store.commit('deleteUser');
    return this.db().destroy();
  }
}

export default new Session();
