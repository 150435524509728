import PouchDb from 'pouchdb';
import session from './session';
import dataTypes from '../data_types/data_types';
import QaDao from '../dao/qa_dao';
import WorkspaceDao from '../dao/workspace_dao';
import attachmentDao from '../dao/attachment_dao';

class Auth {
  signIn(username, password, success, error) {
    const db = new PouchDb(process.env.VUE_APP_USERS_DB, {
      skip_setup: true,
      auth: {
        username: username,
        password: password,
      },
    });

    db.get(`org.couchdb.user:${username}`)
      .then((userObj) => {
        this.createSession(username, password, userObj, success, error);
      })
      .catch((err) => {
        console.log(err);

        if (
          dataTypes.isObject(err) &&
          (err.error === 'unauthorized' || err.error === 'not_found')
        )
          error('authWrongCredentialsError');
        else if (dataTypes.isObject(err) && err.message === 'Failed to fetch')
          error('authNetworkError');
        else error('authUnknownError');
      });
  }

  createSession(username, password, userObj, success, error) {
    new PouchDb('qa_session')
      .destroy()
      .then(() => {
        const sessionDb = new PouchDb('qa_session');
        const user = {
          _id: 'session',
          username: username,
          password: password,
          fullName: userObj.metadata.fullName,
          appRoles: userObj.metadata.appRoles,
          signedInSince: new Date().toISOString(),
        };
        sessionDb
          .put(user)
          .then(() => new QaDao().createIndexes())
          .then(() => new WorkspaceDao().createIndexes())
          .then(() => attachmentDao.createIndexes())
          .then(success)
          .catch((err) => {
            console.log(err);

            error('authUnknownError');
          });
      })
      .catch((err) => {
        console.log(err);

        error('authUnknownError');
      });
  }
}

export default new Auth();
