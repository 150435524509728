<template>
  <nav class="navbar navbar-light mnrt-navbar">
    <div class="container-fluid">
      <!-- <router-link
        :to="{
          name: 'RootProductList',
        }"
        class="navbar-brand"
      >
        <img src="/qa.png" alt="QA logo" width="91" height="41" />
      </router-link> -->
      <a class="navbar-brand">
        <img src="/qa.png" alt="QA logo" />
      </a>
      <button
        class="navbar-toggler mnrt-navbar-toggler"
        type="button"
        data-bs-toggle="collapse"
        data-bs-target="#navbarNav"
      >
        <span class="navbar-toggler-icon"></span>
      </button>
      <div class="collapse navbar-collapse no-transition" id="navbarNav">
        <ul class="navbar-nav">
          <template v-if="$store.state.user">
            <li class="nav-item">
              <router-link
                :to="{
                  name: 'CreateProductionOrder',
                }"
                class="nav-link"
              >
                Criar ordem de produção
              </router-link>
            </li>
            <li class="nav-item">
              <router-link
                :to="{
                  name: 'ProductionOrderList',
                }"
                class="nav-link"
              >
                Listar ordens de produção
              </router-link>
            </li>
            <li class="nav-item">
              <router-link
                :to="{
                  name: 'Sync',
                }"
                class="nav-link"
              >
                Sincronizar
              </router-link>
            </li>
            <li class="nav-item">
              <router-link
                :to="{
                  name: 'Sync',
                  query: { signingOut: 'true' },
                }"
                class="nav-link"
              >
                Sair do aplicativo
              </router-link>
            </li>
          </template>
          <template v-else>
            <li class="nav-item">
              <router-link
                :to="{
                  name: 'SignIn',
                }"
                class="nav-link"
              >
                Entrar no aplicativo
              </router-link>
            </li>
          </template>
        </ul>
      </div>
    </div>
  </nav>
</template>

<script>
import store from '../store/store';
import authorization from '../auth/authorization';

export default {
  name: 'Navbar',
  data() {
    return {};
  },
  computed: {
    isAdmin() {
      return authorization.isAdmin(store.state.user);
    },
  },
};
</script>
