import camelCase from 'camelcase';
import { dig } from '@kaspernj/object-digger';

const i18n = {
  t(translationPath, optsToOverwrite = {}) {
    return this.translate(translationPath, optsToOverwrite);
  },
  translate(translationPath, optsToOverwrite = {}) {
    if (typeof translationPath === 'string')
      translationPath = translationPath.split('.');

    const options = Object.assign(
      {
        convertPathToCamelCase: true,
      },
      optsToOverwrite
    );

    if (options.convertPathToCamelCase)
      translationPath = translationPath.map((p) => camelCase(p));
    const translation = dig(this.translations, ...translationPath);

    return translation
      ? translation
      : `${translationPath.join('.')} not found.`;
  },
  translations: {
    thirdParty: {
      filepond: {
        labelIdle:
          '<span class="filepond--label-action">Tirar ou escolher fotos</span>',
      },
    },
    models: {
      shared: {
        quantity: 'Quantidade',
        observations: 'Observaçōes',
        createdAt: 'Criado em',
        installedAt: 'Instalado em',
        updatedAt: 'Atualizado em',
        createdBy: 'Criado por (usuário)',
        updatedBy: 'Atualizado por (usuário)',
        createdByFullName: 'Criado por',
        updatedByFullName: 'Atualizado por',
      },
      product: {
        qaIdentifier: 'Código QA',
        mineratecIdentifier: 'Código Mineratec',
      },
      qualityInspection: {
        passed: 'Estado',
        inspectorFullName: 'Inspetor',
      },
    },
    validations: {
      shared: {
        formErrorsSummary:
          'Há erros no formulário. Corrija os campos com problemas e tente novamente.',
        mustBeFilled: 'deve ser preenchido',
        mustBeSelected: 'deve ser selecionado',
        atLeastOneMustBeSelected: 'pelo menos um deve ser selecionado',
        integerGtZero: 'deve ser um inteiro maior do que zero',
      },
      salesOrder: {
        erpIdentifier:
          'quando presente, deve ser um PV do Nomus no formato PVdddd (ex: PV0001)',
      },
      productionOrder: {
        erpIdentifier:
          'deve ser uma ordem de produção Nomus no formato OSd+-dd (ex: OS0914-01)',
        prodMineratecIdentifier:
          'deve ser um código de produto Mineratec (ex: RPL-036-2-01-01-1)',
        prodUsesNfcTag:
          'é obrigatório o uso de tag NFC para esse produto'
      },
      qualityInspection: {
        productRequiresLabeling:
          'faça a etiquetagem e depois marque aqui confirmando que o produto foi etiquetado',
      },
    },
    actions: {
      saved: 'Salvo com sucesso.',
      confirmSave: 'Salvar assim mesmo',
      continueSave: 'Continuar e salvar',
      amend: 'Voltar e ajustar',
      unknownSaveError:
        'Não foi possível salvar. Verifique sua conexão e tente novamente.',
      authSignedIn: 'Autenticado com sucesso.',
      authSignedOut: 'Você saiu do sistema.',
      authNotSignedInError:
        'Você precisa se autenticar antes de acessar essa tela.',
      authUnauthorizedError: 'Você não tem permissão para acessar essa tela.',
      authWrongCredentialsError: 'Usuário ou senha incorreta.',
      authNetworkError:
        'Não foi possível se conectar ao servidor.<br />Verifique sua conexão com a Internet e tente novamente por favor.',
      authUnknownError:
        'Ocorreu um erro, tente novamente por favor.<br />Se esse problema persistir, contate o suporte.',
    },
    components: {
      fullscreenModal: {
        bodyDefault: 'Você tem certeza de que deseja realizar essa ação?',
        confirmActionDefault: 'Sim',
        cancelActionDefault: 'Não, cancelar',
      },
      productionOrderCard: {
        deleteMainText:
          'Você tem certeza de que deseja excluir essa OS? Isso excluirá, também, os produtos e inspeçōes associados.',
        deleteConfirmAction: 'Sim, excluir OS',
        deleteCancelAction: 'Cancelar',
      },
    },
    views: {},
  },
};

export default i18n;
