import { v4 as uuidv4 } from 'uuid';
import WorkspaceDao from './workspace_dao';
import productDao from './product_dao';

class ProductionOrderDao extends WorkspaceDao {
  constructor() {
    super();
  }

  // startDate - ISO 8601 string
  // endDate - ISO 8601 string
  async getProductionOrders(opts, done) {
    try {
      let selector = {};
      switch (opts.filter) {
        case 'tagPending':
          selector = {
            allProductsPassed: { '$eq': true },
            allNfcTagsRecorded: { '$eq': false },
            prodUseNfcTag: { '$eq': true }
          };
          break;
        case 'completed':
          selector = {
            allProductsPassed: { '$eq': true },
            allNfcTagsRecorded: { '$eq': true },
            prodUseNfcTag: { '$eq': true }
          };
          break;
        default: // qualityPending
          selector = {
            allProductsPassed: { '$eq': false },
            allNfcTagsRecorded: { '$eq': false },
            prodUseNfcTag: { '$in': [true, false] }
          };
          break;
      }

      const productionOrdersUsingNfc = await this.db().find({
        selector,
        use_index: 'idx_production_order_filters',
        limit: 99999,
      });

      let productionOrdersNotUsingNfc = { docs: [] };
      if (opts.filter === 'completed') {
        // Since we want all production orders in this scenario, let's also include
        // production orders that have passed inspection but that do not use an NFC tag.
        productionOrdersNotUsingNfc = await this.db().find({
          selector: {
            allProductsPassed: {
              '$eq': true,
            },
            allNfcTagsRecorded: {
              '$eq': false,
            },
            prodUseNfcTag: {
              '$eq': false,
            },
          },
          use_index: 'idx_production_order_filters',
          limit: 99999,
        });
      }

      const productionOrders =
        productionOrdersUsingNfc.docs.concat(productionOrdersNotUsingNfc.docs);

      done(
        productionOrders
          .filter(
            (doc) =>
              doc._id.startsWith('production_order::') && doc.deletedAt === null
          )
          .sort((a, b) => {
            if (a.erpIdentifier > b.erpIdentifier) return 1; // b should go before a
            if (a.erpIdentifier < b.erpIdentifier) return -1; // a should go before b

            return 0; // keep original order
          })
      );
    } catch (err) {
      console.log(err);
    }
  }

  saveProductionOrder(productionOrder, done) {
    Object.assign(productionOrder, {
      _id: `production_order::${uuidv4()}`,
    });
    this.saveObj(productionOrder, done);
  }

  async deleteProductionOrder(productionOrder, done) {
    try {
      const objsToDelete = [productionOrder];

      const resProducts = await this.db().find({
        selector: {
          productionOrderId: {
            '$eq': productionOrder._id,
          },
        },
        use_index: 'idx_production_order_id',
        limit: 99999,
      });
      const products = resProducts.docs;
      objsToDelete.push(...products);

      const resQualInsp = await this.db().find({
        selector: {
          productId: {
            '$in': products.map((p) => p._id),
          },
        },
        use_index: 'idx_product_id',
      });
      const qualInsp = resQualInsp.docs.filter((doc) =>
        doc._id.startsWith('quality_inspection::')
      );
      objsToDelete.push(...qualInsp);

      this.deleteMultipleObjs(objsToDelete, done);
    } catch (err) {
      console.log(err);
    }
  }

  checkIfAllProductsPassed(productionOrder, done) {
    productDao.getProducts(productionOrder._id, async (products) => {
      try {
        let passedQualityInspections = await this.db().find({
          selector: {
            productId: {
              '$in': products.map((p) => p._id),
            },
            passed: {
              '$eq': true,
            },
          },
          use_index: 'idx_product_id_passed',
          limit: 99999,
        });
        passedQualityInspections = passedQualityInspections.docs.filter((doc) =>
          doc._id.startsWith('quality_inspection::')
        );

        if (
          passedQualityInspections.length ===
          Number(productionOrder.prodQuantity)
        ) {
          productionOrder.allProductsPassed = true;
          this.updateObj(productionOrder, done);
        } else {
          done();
        }
      } catch (err) {
        console.log(err);
      }
    });
  }

  async checkIfAllNfcTagsWereRecorded(productionOrder, done) {
    try {
      let productsWithRecordedTag = await this.db().find({
        selector: {
          productionOrderId: {
            '$eq': productionOrder._id,
          },
          nfcTagRecorded: {
            '$eq': true,
          },
        },
        use_index: 'idx_production_order_id_nfc_tag_recorded',
        limit: 99999,
      });
      productsWithRecordedTag = productsWithRecordedTag.docs.filter((doc) =>
        doc._id.startsWith('product::')
      );

      if (
        productsWithRecordedTag.length === Number(productionOrder.prodQuantity)
      ) {
        productionOrder.allNfcTagsRecorded = true;
        this.updateObj(productionOrder, done);
      } else {
        done();
      }
    } catch (err) {
      console.log(err);
    }
  }
}

export default new ProductionOrderDao();
