<template>
  <div class="container-fluid" v-if="product">
    <div class="row justify-content-center">
      <div class="col col-md-6">
        <h1 class="mt-3">Inspeçōes de qualidade</h1>
        <router-link :to="{
          name: 'ProductList',
          params: { productionOrderId: product.productionOrderId },
        }" class="d-block mt-5 btn btn-outline-dark" style="max-width: 290px">
          <i class="bi bi-arrow-left"></i>
          Voltar para a ordem de produção
        </router-link>
        <router-link v-if="!productPassedInspection" :to="{
          name: 'QualityInspection',
        }" class="d-block mt-3 mb-5 btn btn-outline-success" style="max-width: 290px">
          <i class="bi bi-journal-plus"></i>
          Nova inspeção
        </router-link>
        <div v-if="productPassedInspection" class="mt-3 mb-5 alert alert-success">
          <p>Esse produto já foi aprovado pela qualidade.</p>
          <div v-if="product.usesNfcTag">
            <p v-show="product.nfcTagRecorded">
              A tag NFC já foi gravada também, mas é permitido que você grave ela
              novamente se necessário.
            </p>
            <router-link :to="{
              name: 'WriteProductIdToNfc',
              params: { productId: productId },
            }" class="d-block mt-3 btn btn-success" style="max-width: 290px">
              <i class="bi bi-tag"></i>
              {{ writeNfcTagBtnTxt }}
            </router-link>
          </div>
          <div v-else>
            <p>
              <i class="d-inline-block bi bi-dash-square-dotted"></i>
              Esse produto foi configurado como <b>não usando</b> tag NFC.
            </p>
          </div>
        </div>
        <p v-if="!anyQualityInspections">
          Esse produto ainda não foi inspecionado.
        </p>
      </div>
    </div>
    <template v-if="anyQualityInspections">
      <div class="row justify-content-center">
        <div class="col col-md-6">
          <h6 class="mb-3">
            <i>Exibindo da inspeção mais recente para a mais antiga</i>
          </h6>
        </div>
      </div>
      <QualityInspectionCard v-for="qi in qualityInspections" :key="qi._id" :qi="qi" />
    </template>
  </div>
</template>

<script>
import QualityInspectionCard from '../components/QualityInspectionCard';
import productDao from '../dao/product_dao';
import qualityInspectionDao from '../dao/quality_inspection_dao';
import store from '../store/store';
import ProductHelper from '../helpers/product_helper';

export default {
  name: 'QualityInspectionList',
  components: {
    QualityInspectionCard,
  },
  props: {
    productId: {
      type: String,
      required: true,
    },
  },
  created() {
    this.getResources();
  },
  data() {
    return {
      productPassedInspection: false,
      product: null,
      qualityInspections: [],
    };
  },
  methods: {
    getResources() {
      store.commit('activateFullscreenOverlay');

      qualityInspectionDao.getProductQualityInspections(
        this.productId,
        (qis) => {
          this.qualityInspections = qis;
          this.productPassedInspection = ProductHelper.hasPassedInspection(qis);

          productDao.getObjById(this.productId, (product) => {
            this.product = product;
            store.commit('deactivateFullscreenOverlay');
          });
        }
      );
    },
  },
  computed: {
    anyQualityInspections() {
      return this.qualityInspections.length != 0;
    },
    writeNfcTagBtnTxt() {
      if (this.product && this.product.nfcTagRecorded) {
        return 'Gravar tag NFC novamente';
      } else {
        return 'Gravar tag NFC';
      }
    },
  },
};
</script>
