import { v4 as uuidv4 } from 'uuid';
import { workspaceAttachmentsDb } from '../db/db';
import Dao from './dao';

class AttachmentDao extends Dao {
  db() {
    return workspaceAttachmentsDb.db();
  }

  createIndexes() {
    return Promise.resolve(); // Dummy promise that automatically resolves.
  }

  async getAttachmentsForObj(remoteDb, ownerDocIds, done) {
    try {
      let res = await remoteDb.db().find({
        selector: {
          ownerDocId: {
            '$in': ownerDocIds,
          },
        },
        use_index: 'idx_owner_doc_id',
        limit: 99999,
      });

      done(res.docs);
    } catch (err) {
      console.log(err);
    }
  }

  async saveAttachments(metadata, files, done) {
    try {
      const attachments = { _attachments: {} };
      for (let file of files) {
        attachments._attachments[file.name] = {
          content_type: file.type,
          data: file,
        };
      }
      await this.db().put(
        Object.assign(
          {
            _id: `attachment_collection::${uuidv4()}`,
          },
          metadata,
          attachments
        )
      );
      done();
    } catch (err) {
      console.log(err);
    }
  }
}

export default new AttachmentDao();
