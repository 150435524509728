<template>
  <div class="container-fluid">
    <div class="row justify-content-center">
      <div class="col col-md-4">
        <h1 class="mt-3">Entrar no QA</h1>
        <form @submit.prevent="signIn">
          <label for="username" class="mt-3 form-label">Usuário</label>
          <input
            v-model="username"
            id="username"
            class="form-control"
            type="text"
          />
          <label for="password" class="mt-3 form-label">Senha</label>
          <input
            v-model="password"
            id="password"
            class="form-control"
            type="password"
          />
          <button class="mt-3 btn btn-success" :class="btnSignInClasses">
            <template v-if="authenticating">
              <span
                class="spinner-border spinner-border-sm me-2"
                role="status"
                aria-hidden="true"
              ></span>
              <span>Carregando...</span>
            </template>
            <span v-else>Entrar</span>
          </button>
          <div v-html="errorMsg" class="my-3 text-danger"></div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import i18n from '../i18n/i18n';
import auth from '../auth/auth';
import { useToast } from 'vue-toastification';

export default {
  name: 'SignIn',
  setup() {
    return { toast: useToast() };
  },
  data() {
    return {
      username: '',
      password: '',
      errorMsg: '',
      authenticating: false,
    };
  },
  computed: {
    btnSignInClasses() {
      return {
        disabled: this.authenticating,
      };
    },
  },
  methods: {
    signIn() {
      this.errorMsg = '';
      this.authenticating = true;

      auth.signIn(this.username, this.password, this.success, this.error);
    },
    success() {
      this.authenticating = false;
      this.toast.success(i18n.translate('actions.authSignedIn'));

      this.$router.push({
        path: '/',
      });
    },
    error(errType) {
      this.authenticating = false;
      this.errorMsg = i18n.translate(`actions.${errType}`);
    },
  },
};
</script>
